import {
  Button,
  chakra,
  FormControl,
  FormLabel,
  Input,
  Stack,
} from "@chakra-ui/react";
import PasswordField from "@components/PasswordField";
import { useRouter } from "next/router";
import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import toast from "react-hot-toast";
import { convertErrorMsg } from "lib/convertErrorMsg";
import { auth } from "lib/firebase";

const LoginForm = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const router = useRouter();

  return (
    <chakra.form
      onSubmit={(e) => {
        e.preventDefault(); // your login logic here

        if (!email || !password) return;

        setLoading(true);

        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            toast.success("Signed in successfully");
            router.push("/dashboard");
            setPassword("");
            setLoading(false);
          })
          .catch((err) => {
            console.error(err);
            toast.error(convertErrorMsg(err.code));
            setPassword("");
            setLoading(false);
          });
      }}
      {...props}
    >
      <Stack spacing="6">
        <FormControl id="email">
          <FormLabel>Email address</FormLabel>
          <Input
            datatestid="email"
            placeholder="Enter email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            name="email"
            type="email"
            autoComplete="email"
            required
          />
        </FormControl>
        <PasswordField value={password} onChange={setPassword} />
        <Button
          isDisabled={!email || !password}
          isLoading={isLoading}
          type="submit"
          size="lg"
          fontSize="md"
          datatestid="submitLogin"
        >
          Sign in
        </Button>
      </Stack>
    </chakra.form>
  );
};

export default LoginForm;
