// Used for taking Firebase error codes (err.code) and converting them to a user friendly message.

export const convertErrorMsg = (error) => {
  switch (error) {
    case "auth/user-not-found":
      return "We looked everywhere, but couldn't find you! Did you spell the email correctly?";
    case "auth/wrong-password":
      return "That password is incorrect. Try again?";
    case "auth/invalid-email":
      return "That email is invalid. Try again?";
    case "auth/email-already-in-use":
      return "That email is already in use. Try again?";
    case "auth/weak-password":
      return "That password is too weak. Try again?";
    case "permission-denied":
      return "You don't have permission to perform that action.";
    case "storage/unauthorized":
      return "You don't have permission to access that file.";
    case "auth/popup-closed-by-user":
      return "Authentication did not complete fully. You probably closed the pop-up.";
    case "auth/user-not-found":
      return "We looked everywhere, but couldn't find you! Did you spell the email correctly?";
    default:
      return `Something went wrong... ${error ? `(${error})` : ""}`;
  }
};
