import { LINKEDIN_CONVERSION_IDS, linkedInTrack } from "@lib/linkedInTrack";
import { logEvent } from "firebase/analytics";
import {
  GithubAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  TwitterAuthProvider,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { convertErrorMsg } from "lib/convertErrorMsg";
import { analytics, auth, db } from "lib/firebase";
import toast from "react-hot-toast";

export const signInUsingOAuth = async (authProvider) => {
  var provider;
  if (authProvider === "google") provider = new GoogleAuthProvider();
  if (authProvider === "twitter") provider = new TwitterAuthProvider();
  if (authProvider === "github") provider = new GithubAuthProvider();
  if (authProvider === "microsoft")
    provider = new OAuthProvider("microsoft.com");

  const result = await signInWithPopup(auth, provider)
    .then(async (result) => {
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      toast.success("Successfully signed in.");

      const isNewUser = result._tokenResponse.isNewUser || false;

      if (isNewUser) {
        linkedInTrack(LINKEDIN_CONVERSION_IDS.register);
      }

      logEvent(analytics, isNewUser ? "sign_up" : "sign_in", {
        method: authProvider,
      });
      await setUserInFirestore(result.user);
      return result;
    })
    .catch((err) => {
      toast.error(convertErrorMsg(err.code));
      console.error(err);
    });

  return result?.user || false;
};

export const setUserInFirestore = async (user) => {
  const userRef = doc(db, "users", user.uid);

  delete user.email;

  await setDoc(userRef, JSON.parse(JSON.stringify(user)), { merge: true });
};
