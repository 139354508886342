export const LINKEDIN_CONVERSION_IDS = {
  register: 17064610,
};

export const lintrk = (action, options) => {
  window?.lintrk(action, options);
};

export const linkedInTrack = (conversionId) => {
  lintrk("track", { conversion_id: conversionId });
};
