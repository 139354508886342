import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  FormErrorMessage,
  FormHelperText,
  Text,
  Link,
} from "@chakra-ui/react";
import { createUserWithEmailAndPassword, updateProfile } from "@firebase/auth";
import { createFirebaseUser } from "@lib/auth";
import { LINKEDIN_CONVERSION_IDS, linkedInTrack } from "@lib/linkedInTrack";
import { logEvent } from "firebase/analytics";
import { sendEmailVerification } from "firebase/auth";
import { convertErrorMsg } from "lib/convertErrorMsg";
import { analytics, auth } from "lib/firebase";
import { useRouter } from "next/router";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

const RegisterForm = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [user] = useAuthState(auth);
  const router = useRouter();

  const { email } = router.query;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: email || "",
    },
  });

  const onSubmit = async (data) => {
    const { displayName, confirmPassword, email, password } = data;

    if (user) return;

    if (password !== confirmPassword) {
      return toast.error("Passwords do not match");
    }

    setLoading(true);

    createUserWithEmailAndPassword(auth, email, password)
      .then(({ user }) => {
        updateProfile(user, { displayName })
          .then(() => {
            // user is created
            auth.currentUser.getIdToken(true); // refresh the token to include new displayName

            // send email verification
            sendEmailVerification(auth.currentUser);

            const userAsJSON = {
              ...auth.currentUser.toJSON(),
              displayName,
            };

            // remove undefined values
            Object.keys(userAsJSON).forEach(
              (key) => userAsJSON[key] === undefined && delete userAsJSON[key]
            );

            // save user to firestore
            createFirebaseUser(userAsJSON);

            // tracking
            logEvent(analytics, "sign_up", { method: "emailAndPassword" });
            linkedInTrack(LINKEDIN_CONVERSION_IDS.register);

            // redirects if query param is passed
            if (router.query.redirect) {
              router.push(router.query.redirect);
            } else {
              router.push("/dashboard");
            }

            setLoading(false);
          })
          .catch((err) => {
            console.error(err);
            toast.error(convertErrorMsg(err.code));
          });
      })
      .catch((err) => {
        toast.error(convertErrorMsg(err.code));
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} {...props}>
      <Stack spacing={6}>
        <FormControl isInvalid={errors.displayName}>
          <FormLabel>Name</FormLabel>
          <Input
            autoFocus
            datatestid="username"
            placeholder="Enter name"
            maxLength={32}
            {...register("displayName", {
              required: "This field is required.",
              minLength: {
                value: 4,
                message: "Minimum length should be 4 characters.",
              },
              maxLength: {
                value: 32,
                message: "Maximum length should be 32 characters.",
              },
            })}
          />
          <FormHelperText>
            This is the name people will see in sessions.
          </FormHelperText>
          <FormErrorMessage>{errors.displayName?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.email}>
          <FormLabel>Email address</FormLabel>

          <Input
            datatestid="email"
            placeholder="Enter email"
            maxLength={64}
            type="email"
            autoComplete="email"
            {...register("email", {
              required: "This field is required.",
              pattern: {
                value:
                  '/^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/',
                message: "The email should be in the valid format.",
              },
              minLength: {
                value: 4,
                message: "Minimum length should be 4 characters.",
              },
              maxLength: {
                value: 64,
                message: "Maximum length should be 32 characters.",
              },
            })}
          />
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.password}>
          <FormLabel>Password</FormLabel>

          <Input
            datatestid="password"
            placeholder="Enter password"
            label="Password"
            type="password"
            autoComplete="password"
            maxLength={128}
            {...register("password", {
              required: "This field is required.",
              minLength: {
                value: 4,
                message: "Minimum length should be 4 characters.",
              },
              maxLength: {
                value: 128,
                message: "Maximum length should be 128 characters.",
              },
            })}
          />
          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.confirmPassword}>
          <FormLabel>Confirm password</FormLabel>

          <Input
            datatestid="confirmPassword"
            placeholder="Confirm password"
            label="Confirm password"
            type="password"
            autoComplete="password"
            maxLength={128}
            {...register("confirmPassword", {
              required: "This field is required.",
              minLength: {
                value: 4,
                message: "Minimum length should be 4 characters.",
              },
              maxLength: {
                value: 128,
                message: "Maximum length should be 128 characters.",
              },
            })}
          />
          <FormErrorMessage>{errors.confirmPassword?.message}</FormErrorMessage>
        </FormControl>

        <Button
          isDisabled={errors.length > 0}
          isLoading={isLoading}
          type="submit"
          size="lg"
          fontSize="md"
          datatestid="submitRegister"
        >
          Create account
        </Button>

        <Text align="center" fontSize="sm">
          By signing up, you agree to our{" "}
          <Link href="/terms" target="_blank" color="brand.300">
            Terms of Service
          </Link>{" "}
          and{" "}
          <Link href="/privacy" target="_blank" color="brand.300">
            Privacy Policy
          </Link>
          .
        </Text>
      </Stack>
    </form>
  );
};

export default RegisterForm;
