import {
  FormControl,
  Flex,
  FormLabel,
  InputGroup,
  InputRightElement,
  Input,
  IconButton,
} from "@chakra-ui/react";
import { Eye, EyeClosed } from "@phosphor-icons/react";
import { useState } from "react";
import Link from "@components/Link";

const PasswordField = ({ value, onChange, hideForgot, label }) => {
  const [isShow, setShow] = useState(false);
  const onToggle = () => setShow(!isShow);

  return (
    <FormControl id="password">
      <Flex justify="space-between">
        <FormLabel>{label ? label : "Password"}</FormLabel>
        {!hideForgot && (
          <Link fontSize="sm" href="/forgot-password">
            Forgot password?
          </Link>
        )}
      </Flex>

      <InputGroup>
        <InputRightElement>
          <IconButton
            bg="transparent !important"
            variant="ghost"
            colorScheme="gray"
            aria-label={isShow ? "Mask password" : "Reveal password"}
            icon={isShow ? <Eye /> : <EyeClosed />}
            onClick={onToggle}
          />
        </InputRightElement>
        <Input
          datatestid="password"
          placeholder="Enter password"
          name="password"
          type={isShow ? "text" : "password"}
          autoComplete="current-password"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          required
        />
      </InputGroup>
    </FormControl>
  );
};

export default PasswordField;
