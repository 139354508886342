import { Box } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/color-mode";

const CardBox = (props) => (
  <Box
    bg={useColorModeValue("white", "gray.700")}
    py={8}
    px={{
      base: "4",
      md: "10",
    }}
    shadow="base"
    rounded="lg"
    {...props}
  />
);

export default CardBox;
