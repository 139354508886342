import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/color-mode";
import Card from "@components/CardBox";
import Link from "@components/Link";
import LoginForm from "@components/auth/LoginForm";
import Logo from "@components/Logo";
import RegisterForm from "./RegisterForm";
import { useRouter } from "next/router";
import { auth } from "lib/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect } from "react";
import Image from "next/image";
import { signInUsingOAuth } from "services/AuthService";

const AuthBox = ({ isRegister }) => {
  const { push, query } = useRouter();
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    // If the user is already logged in and there's no redirect query, go to dashboard
    if (user?.displayName && !query.redirect) {
      push("/dashboard");
    }
  }, [user]);

  const signIn = async (provider) => {
    await signInUsingOAuth(provider).then((result) => {
      if (!result) return;

      if (query.redirect) push(query.redirect);
      else push("/dashboard");
    });
  };

  if (loading && !user) {
    return null;
  }

  return (
    <Box
      bg={useColorModeValue("gray.50", "inherit")}
      minH="100vh"
      py="12"
      px={{
        base: "4",
        lg: "8",
      }}
    >
      <Box maxW="md" mx="auto">
        <Link href="/">
          <Logo
            _hover={{ opacity: 0.8 }}
            mx="auto"
            h="12"
            mb={{
              base: "5",
              md: "10",
            }}
          />
        </Link>
        <Heading textAlign="center" size="xl" fontWeight="bold">
          {isRegister ? "Create a new account" : "Sign in to your account"}
        </Heading>

        {isRegister ? (
          <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
            <Text as="span">Have an account already?</Text>
            <Link ml={1} href="/login">
              Simply login to it!
            </Link>
          </Text>
        ) : (
          <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
            <Text as="span">Don&apos;t have an account?</Text>
            <Link
              ml={1}
              href={
                query.redirect
                  ? `/register?redirect=${query.redirect}`
                  : "/register"
              }
            >
              Create one now!
            </Link>
          </Text>
        )}

        <Card>
          <Button
            colorScheme="gray"
            variant="outline"
            width="full"
            size="lg"
            fontSize="md"
            mb={8}
            leftIcon={
              <Image
                width="20px"
                height="20px"
                alt="Google Logo"
                src="/img/icons/google.svg"
              />
            }
            onClick={() => signIn("google")}
          >
            Login with Google
          </Button>

          {isRegister ? <RegisterForm /> : <LoginForm />}
        </Card>
      </Box>
    </Box>
  );
};

export default AuthBox;
